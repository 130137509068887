





















































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    BaseToolbar
  }
})
export default class RoomLayoutsToolbar extends BaseToolbar {
  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get addLayout() {
    return this.localComponent.addLayout || this.emptyFunction;
  }

  get editorOpen(): boolean {
    return this.localComponent.editorOpen || false;
  }

  get toggleLayoutTableEdit() {
    return this.localComponent.toggleLayoutTableEdit || this.emptyFunction;
  }

  get confirmDeleteLayouts() {
    return this.localComponent.confirmDeleteLayouts || this.emptyFunction;
  }

  get layoutTableEdit() {
    return this.localComponent.layoutTableEdit;
  }

  get isSelectedLayoutsEmpty() {
    return this.localComponent.isSelectedLayoutsEmpty;
  }

  get layoutSearch() {
    return this.localComponent.layoutSearch || '';
  }

  set layoutSearch(value: string) {
    this.localComponent.layoutSearch = value;
  }
}
